<template>
  <b-container class="user-wallet-container">
    <withdraw-modal />
    <deposite-modal />
    <b-row>
      <b-col cols="12">
        <balance-card :data="currentBalance" classes="user-wallet-primary">
          <div class="d-flex justify-content-between w-100 mt-2">
            <app-button
              class="btn-payment withdraw"
              @click.native="$bvModal.show('withdraw-payment')"
            >
              {{ $t("endUser.balance_withdrawal") }}
            </app-button>
            <app-button
              class="w-50"
              @click.native="$bvModal.show('add-payment')"
            >
              {{ $t("endUser.top_up") }}
            </app-button>
          </div>
        </balance-card>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col
            lg="3"
            sm="6"
            cols="12"
            v-for="(item, key) in walletStats"
            :key="key"
          >
            <balance-card
              :data="item"
              classes="user-wallet-small"
              :currency="item.currency"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="iq-card mt-4 pay-operations">
      <div
        class="iq-card-body d-flex align-items-center justify-content-between"
      >
        <h3 class="iq-card-title d-flex align-items-center gap_1">
          <!--          <i class="las la-wallet"></i>--><span>{{
            $t("endUser.all_operations")
          }}</span>
        </h3>
      </div>
    </div>
    <div class="iq-card">
      <div class="iq-card-body">
        <b-row class="align-items-center">
          <b-col cols="3">
            {{ $t("endUser.operation") }}
          </b-col>
          <b-col cols="3">
            <div class="wallet-box-info">
              <h6 class="wallet-box-info-title">{{ $t("endUser.date") }}</h6>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="wallet-box-info">
              <h6 class="wallet-box-info-title">
                {{ $t("endUser.paymentMethod") }}
              </h6>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="wallet-box-info">
              <h6 class="wallet-box-info-title">{{ $t("endUser.amount") }}</h6>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="iq-card" v-for="(i, key) in allTransactions" :key="key">
      <div class="iq-card-body">
        <b-row class="align-items-center">
          <b-col cols="3">
            <div class="wallet-operation-info d-flex gap_1">
              <div
                class="icon d-flex align-items-center justify-content-center"
                v-if="i.type === 'deposit'"
              >
                <i class="las la-arrow-up"></i>
              </div>
              <div
                class="icon d-flex align-items-center justify-content-center"
                v-if="i.type === 'withdraw'"
              >
                <i class="las la-arrow-down"></i>
              </div>
              <div
                class="icon d-flex align-items-center justify-content-center"
                v-if="i.type === 'purchase'"
              >
                <i class="las la-money-bill"></i>
              </div>
              <div>
                <h6 class="wallet-operation-info-title">
                  {{ operationType(i.type) }}
                </h6>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="wallet-box-info">
              <h6 class="wallet-box-info-response">
                {{ i.created_at | formateDate }}
              </h6>
            </div>
          </b-col>
          <b-col cols="3">
            <div
              class="wallet-box-info"
              v-if="i.payment_method_type === 'CARD'"
            >
              <h6 class="wallet-box-info-response">
                <span>{{
                  $i18n.locale === "ar" ? "بطاقه ائتمانيه" : "credit card"
                }}</span>
              </h6>
            </div>
            <div class="wallet-box-info" v-else>
              <h6 class="wallet-box-info-response">
                <span>{{ $i18n.locale === "ar" ? "المحفظة" : "wallet" }}</span>
              </h6>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="wallet-box-info">
              <h6
                class="wallet-box-info-response wallet-box-info-price"
                v-if="i.type === 'deposit'"
              >
                {{ i.amount }} {{ $t("main.sar") }}
              </h6>
              <h6 class="wallet-box-info-response wallet-box-info-price" v-else>
                {{ i.amount }} {{ $t("main.sar") }}
              </h6>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <app-pagination :meta="meta" @input="getWalletTransaction" />
  </b-container>
</template>
<script>
import balanceCard from "../../components/balanceCard";
import btnWithIcon from "../../components/btnWithIcon";
import websiteServices from "../../services/userProfile";

import moment from "moment";
import AppButton from "../../../../components/buttons/appButton.vue";
import DepositeModal from "../../../../components/modal/depositModal.vue";
import WithdrawModal from "../../../../components/modal/withdrawModal.vue";
import AppPagination from "../../../../components/appPagination.vue";

export default {
  name: "wallet",
  data() {
    return {
      showFilter: false,
      paymentInfo: {},
      currentBalance: {
        balance: "",
        title: this.$i18n.locale === "ar" ? "الرصيد الحالي" : "Current balance",
      },
      walletStats: {
        totalDepositMoney: {
          balance: "",
          title:
            this.$i18n.locale === "ar" ? "اجمالى الايداع" : "Total deposit",
          currency: true,
        },
        totalWithdrawMoney: {
          balance: "",
          title:
            this.$i18n.locale === "ar" ? "اجمالى السحب" : "Total withdrawal",
          currency: true,
        },
        totalDeposit: {
          balance: "",
          title:
            this.$i18n.locale === "ar"
              ? "عدد عمليات الايداع"
              : "Number of deposits",
          currency: false,
        },
        totalWithdraw: {
          balance: "",
          title:
            this.$i18n.locale === "ar"
              ? "عدد عمليات السحب"
              : "Number of withdrawals",
          currency: false,
        },
      },

      allTransactions: [],
      meta: {},
      hyperpayForm: false,
    };
  },
  components: {
    balanceCard,
    btnWithIcon,
    AppButton,
    DepositeModal,
    WithdrawModal,
    AppPagination,
  },
  methods: {
    operationType(type) {
      if (type === "withdraw") {
        return this.$i18n.locale === "ar" ? "سحب" : "withdraw";
      } else if (type === "deposit") {
        return this.$i18n.locale === "ar" ? "إيداع" : "deposit";
      } else {
        return this.$i18n.locale === "ar" ? "شراء" : "purchase";
      }
    },

    async getWallaetDetails() {
      const { data } = await websiteServices.getWallaetDetails();
      this.currentBalance.balance = data.data.balance;
      for (const key in data.data) {
        if (this.walletStats[key])
          this.walletStats[key].balance = data.data[key];
      }
    },

    async getWalletTransaction(page) {
      const { data } = await websiteServices.getAllTransactions({ page });
      console.log(data);
      this.allTransactions = data.data;
      this.meta = data.meta;
      console.log(page);
    },
  },
  filters: {
    formateDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
  created() {
    this.getWallaetDetails();
    this.getWalletTransaction();
  },
};
</script>
<style lang="scss" scoped>
.iq-card {
  border-radius: 4px !important;
}

.user-wallet-primary {
  position: relative;

  .setting-button {
    position: absolute;
    top: 2rem;
    left: 2rem;
    @media (max-width: 545px) {
      top: 1rem;
      left: 1rem;
    }
  }
}

.wallet-options {
  width: 100%;
}

.btn-payment {
  width: 49%;
  @media (max-width: 545px) {
    width: 45%;
    padding: 0.5rem 0;
    min-width: unset !important;
  }
}

.withdraw {
  background: transparent !important;
  border: 1px solid #00443d !important;
  color: #00443d !important;
}

.pay-operations {
  h3 {
    color: #000 !important;
    font-size: 1.5rem;
  }
}

.la-arrow-up,
.la-arrow-down,
.la-money-bill {
  transform: rotate(45deg);
  color: #2ac066;
  font-size: 1.5rem;
  font-weight: bold;
}

.la-arrow-down {
  transform: rotate(45deg);
  color: #ee9c3c;
}
.la-money-bill {
  transform: rotate(0);
  color: #00443d;
}
</style>
