<template>
  <b-modal
    id="withdraw-payment"
    class="main-modal"
    size="md"
    centered
    hide-header
    hide-footer
  >
    <div
      class="d-flex justify-content-between align-content-center border-bottom modal-head"
    >
      <div class="d-flex align-items-center gap_2">
        <h4 class="title">
          {{ $t("payment.withdraw") }}
        </h4>
      </div>
      <b-button
        @click="$bvModal.hide('withdraw-payment')"
        class="border-0 bg-transparent p-0 m-0 back"
      >
        <i class="las la-times"></i>
      </b-button>
    </div>
    <div class="pt-3">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(withdrawRequest)">
          <div class="position-relative">
            <input-form
              name="withdraw-balance"
              :label="$t('payment.withdraw_amount')"
              placeholder=""
              v-model.number="withdraw.amount"
            >
              <template #append>
                <span class="sub-label">
                  {{ $t("main.sar") }}
                </span>
              </template>
            </input-form>
          </div>
          <app-button
            :loading="loadingWidthdrawButton"
            @click="withdrawRequest"
          >
            {{ $t("payment.withdraw_request") }}
          </app-button>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import { core } from "@/config/pluginInit";
import websiteServices from "../../modules/userProfile/services/userProfile";
import appButton from "../buttons/appButton.vue";

export default {
  components: { appButton },
  data() {
    return {
      loadingWidthdrawButton: false,
      withdraw: {
        amount: "",
      },
    };
  },
  methods: {
    async withdrawRequest() {
      this.loadingWidthdrawButton = true;
      try {
        const { data } = await websiteServices.requestWithdraw(this.withdraw);
        core.showSnackbar("success", data.message);
        this.$bvModal.hide("withdraw-payment");
      } catch (err) {
        core.showSnackbar("error", err.response.data.message);
      } finally {
        this.loadingWidthdrawButton = false;
      }
    },
  },
};
</script>

<style>
</style>