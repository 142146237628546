<template>
<div :class="classes" class="user-wallet iq-card text-center d-flex align-items-center justify-content-center">
  <div class="iq-card-body w-100">
    <h3 class="balance-title">{{data.title}}</h3>
    <h2 class="balance">{{data.balance}} <span class="cuurency" v-if="currency">{{ $t('main.sar') }}</span></h2>
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  props: ['data', 'classes', 'currency']
}
</script>
<style lang="scss" scoped>
.iq-card-body{
  padding: 2rem !important;
  @media (max-width: 545px) {
    padding: 1rem !important;
  }
}
</style>
