<template>
  <b-modal
    id="add-payment"
    class="main-modal"
    size="md"
    centered
    hide-header
    hide-footer
  >
    <div
      class="d-flex justify-content-between align-content-center border-bottom modal-head"
    >
      <div class="d-flex align-items-center gap_2">
        <h4 class="title">
          {{ $t("payment.add_balance_wallet") }}
        </h4>
      </div>
      <b-button
        @click="$bvModal.hide('add-payment')"
        class="border-0 bg-transparent p-0 m-0 back"
      >
        <i class="las la-times"></i>
      </b-button>
    </div>
    <div class="position-relative mt-2">
      <input-form
        name="deposit-balance"
        :label="$t('payment.balance_charge')"
        placeholder=""
        v-model.number="amount"
      >
        <template #append>
          <span class="sub-label">
            {{ $t("main.sar") }}
          </span>
        </template>
      </input-form>
    </div>
    <ul
      class="deposite__examples d-flex align-items-center gap_1 p-0"
      :class="{
        'justify-content-end': this.$i18n.locale === 'ar',
      }"
    >
      <li v-for="(item, index) in [100, 500, 1000, 2000]" :key="index">
        <button
          @click="examplesCheck(item)"
          :class="{ active: amount === item }"
        >
          {{ item }}
        </button>
      </li>
    </ul>
    <app-button :loading="loading" @click.native="deposit">
      {{ $t("payment.charge") }}
    </app-button>
  </b-modal>
</template>

<script>
import appButton from "../buttons/appButton.vue";
import userWallet from "../../modules/userProfile/services/userProfile";
export default {
  components: { appButton },
  data() {
    return {
      amount: null,
      loading: false,
    };
  },
  methods: {
    examplesCheck(balance) {
      this.amount = balance;
    },
    async deposit() {
      if (!this.amount) return;
      const { amount } = this;
      this.loading = true;
      const { data } = await userWallet.deposit({ amount });
      const { checkout_url } = data;
      window.open(checkout_url, "_self");
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
$secondary-color: #d39d45;
.deposite__examples {
  li {
    button {
      border: 1px solid #e5e5e5;
      border-radius: 5px !important;
      padding: 0.25rem 1rem;
      font-size: 1rem;
      color: #000;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgba($color: $secondary-color, $alpha: 0.2);
      }
      &.active {
        background-color: $secondary-color;
        color: #fff;
      }
    }
  }
}
</style>